/* Styles for the main image container */
.image-container {
    background-color: #ebebeb;
    border-radius: 0.5rem; /* 8px */
}

/* Styles for the main image */
.main-image {
    width: 310px;
    height: 310px;
}

@media (min-width: 640px) { /* sm: breakpoint */
    .main-image {
        width: 330px;
        height: 330px;
    }
}

@media (min-width: 1024px) { /* lg: breakpoint */
    .main-image {
        width: 350px;
        height: 350px;
    }
}

/* Styles for the thumbnail images container */
.thumbnail-container {
    display: flex;
    gap: 5px;
    margin-top: 1.25rem; /* 20px */
}

@media (min-width: 640px) { /* sm: breakpoint */
    .thumbnail-container {
        gap: 16px;
    }
}

@media (min-width: 1024px) { /* lg: breakpoint */
    .thumbnail-container {
        gap: 23px;
    }
}

/* Styles for individual thumbnails */
.thumbnail-image {
    border-radius: 0.5rem; /* 8px */
    cursor: pointer;
    background-color: #ebebeb;
    width: 70px;
    height: 70px;
}

/* Active thumbnail class */
.thumbnail-image.active {
    background-color: red; /* replace with your `bg-second` color */
}

#b2b--header{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
}
.call-to-action {
    padding: 12px 22px;
    border-radius: 12px;
    color: rgba(0, 0, 0, 0.674);
    font-weight: bold;
}
   
@media screen and (max-width: 1000px) {
    #b2b--header{
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 1200px) {
    #menu-secondary-nav{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
}
.blog-container {
    background-color: #ffffff;
  }
  
  .blog-header-image {
    width: 100%;
    height: auto;
  }
  
  .blog-title-container {
    padding: 0 20px;
  }
  
  .blog-title {
    font-size: 1.5rem;
    color: #1e3a8a;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 0.5rem;
  }
  
  .blog-title-underline {
    height: 4px;
    width: 15rem;
    background-color: #374151;
    margin-bottom: 0.5rem;
  }
  
  .blog-content {
    max-width: 1120px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 20px;
  }
  
  .blog-card {
    padding: 1rem;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .blog-card:hover {
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .blog-card-image {
    height: 12rem;
    width: 100%;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  
  .blog-card-category {
    font-size: 0.875rem;
    color: #1e3a8a;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .blog-card-title {
    font-size: 1.125rem;
    font-weight: bold;
    color: #1e3a8a;
    margin-bottom: 0.5rem;
  }
  
  .blog-card-description {
    font-size: 0.875rem;
    color: #4b5563;
    text-align: justify;
  }
  .blog1-container {
    max-width: 1120px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .blog1-nav {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: #4b5563; 
    margin-bottom: 1rem;
  }
  
  .blog1-link {
    color: #1e3a8a; 
    text-decoration: none;
  }
  
  .blog1-link:hover {
    text-decoration: underline;
  }
  
  .blog1-category {
    color: #9ca3af; 
  }
  
  .blog1-title-nav {
    color: #374151;
  }
  
  .blog1-image {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
  
  .blog1-title {
    font-size: 2rem;
    font-weight: bold;
    color: #1e3a8a;
    margin-bottom: 1rem;
  }
  
  .blog1-meta {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 1.5rem;
  }
  
  .blog1-divider {
    height: 2px;
    width: 100%;
    background-color: #e5e7eb; 
    margin-bottom: 1rem;
  }
  
  .blog1-content {
    font-size: 1rem;
    color: #4b5563; 
    text-align: justify;
  }
  
  .blog1-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }
  
  .blog1-button {
    font-size: 1rem;
    color: #1e3a8a; 
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .blog1-button:hover {
    text-decoration: underline;
  }
  
  .blog1-button-disabled {
    color: #9ca3af;
    cursor: not-allowed;
  }
  

@import 'mixins.scss';
@import 'login.scss';

ul.products[data-view="list-view"] > li.product .product-item__footer {
    width: fit-content !important;
    @include atMedium() {
        width: 25% !important;
    }
}

/* Styles for the main image container */
.image-container {
    background-color: #ebebeb;
    border-radius: 0.5rem; /* 8px */
}

/* Styles for the main image */
.main-image {
    width: 100%;
    height: 100%;
}

.parent-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin : 0 auto 35px auto
}


/* Styles for the thumbnail images container */
.thumbnail-container {
    display: flex;
    gap: 5px;
    margin-top: 1.25rem; /* 20px */
}

/* Styles for individual thumbnails */
.thumbnail-image {
    border-radius: 0.5rem; /* 8px */
    cursor: pointer;
    background-color: #ebebeb;
    width: 70px;
    height: 70px;
}

/* Active thumbnail class */
.thumbnail-image.active {
    background-color: #ffc75e; /* replace with your `bg-second` color */
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,#fba707 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%,#fba707);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation: l13 1s infinite linear;
}

.closeVisible {
    display: block !important;
}

.swiper-wrapper{
    display: inline-flex !important;
}

.costum-page-numbers{
    font-size: 19px !important;
    padding: 0px 8px !important;
    margin: 0 10px !important;
}

.profile-info-summery-wrapper {

    display: flex;
    justify-content: center;
    align-items: center;

    .profile-info-summery{
        display: flex;
        flex-direction: column;
        align-items: center !important;
        gap: 12px;

        .profile-summery-info-wrapper{
            background: white;
            padding: 15px;
            border-radius: 14px;
            margin-left: 0 !important;
            margin-bottom: .5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .dokan-store-support-and-follow-wrap {
            margin-bottom: 1rem !important;
            margin-left: unset !important;
        }

    }

}

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding-top: 50px;
    padding-bottom: 50px;
    @include atLarge () {
        padding-top: 0;
    }
}

.my-account {
    position: relative;
    z-index: 99999;

    ul {
        display: none;
        padding: 10px;
        list-style: none;
        margin-top: 0;
        background: white;
        border: 1px solid #ddd;
        border-radius: 6px;
        box-shadow: 0px 5px 7px 0px #3c3c3c;
        position: absolute;
        border-bottom: 3px solid #686666;
        right: 0;

        li {
            margin-left: 0 !important;
            cursor: pointer;
            padding: 10px 0;
            display: flex;
            align-items: center;
            gap: 0 5px;
            text-wrap: nowrap;

            i {
                font-size: 16px;
            }

        }
    }

    &:hover {
        ul {
            display: block;
        }
    }
}

.errors-bar {
    list-style: none;
    margin-top: 10px;
    font-weight: 700;
    background: red;
    color: white;
    padding: 10px;
    border-radius: 10px;
    li {
        padding: 10px 0;
    }
}

.avantages-buttons {
    margin: 45px 0px;

    a {
        color: rgb(247, 190, 104);
        border-color: rgb(247, 190, 104);
        background-color: transparent;
        padding: 13px 19px;
        font-size: 14px;
        outline: auto;
        border-radius: 2em;
        border-width: 2px;
        transition: background .3s ease;

        &:hover {
            color: white;
            background-color: rgb(247, 190, 104);
            outline: auto;
            border-color: rgb(247, 190, 104);
        }
    }

    i {
        margin-left: 5px;
    }
}

.contact-section-contain {
    background-color: #D6D6D6;
    border-width: 0; 
    border-radius: 10px;
    padding: 0 12px;

    .contact-section {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 40px 0;
        margin-top: 20px;

        .contact-section-1 {
            width: 100%;
            @include atMedium(){
                width: 45%;
            }
        }

        .contact-section-2 {
            width: 100%;
            background-color: #fff;
            padding: 35px 15px;
            border-radius: 10px;
            @include atMedium(){
                width: 50%;
            }
        }

        
        @include atMedium(){
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }


    }

}

// hide scrollbar 
.hide-scrollbar::-webkit-scrollbar {
    display: none !important;
}

// style for background when user open side filter

.active-hh-sidebar {

    .sideFilter::before {
        background: rgba(0, 0, 0, .7);
        content: " ";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 10;
    }

}

// style for errors message
.error-text{
    color: red;
    font-weight: 500;
    padding: 5px 0;
}

@keyframes l13{ 
    100%{transform: rotate(1turn)}
}

@include atXSmall {
    .parent-image-container{
        width: 80%;
    }
}

@include atMedium {
    .profile-info-summery-wrapper {
        display: block;
        .profile-info-summery{
            gap: 14px;
            flex-direction: row;
            align-items: flex-end !important;
        
            .profile-summery-info-wrapper{
                align-items: flex-start;
            }

            .dokan-store-support-and-follow-wrap {
                text-align: right;
                margin-left: auto !important;
                margin-bottom: 1.5rem !important;
            }
        
        }
    }
}

@include atMedium {
    .parent-image-container {
        align-items: flex-start;
        margin : 0;
        width: 100%;
    }
    
    .thumbnail-container {
        gap: 16px;
    }
}

@include atLarge {
    .main-image {
        width: 330px;
        height: 330px;
    }
    .thumbnail-container {
        gap: 23px;
    }
}

@include atXLarge{
    .closeVisible{
        display: none !important;
    }
}

@media screen and (max-width: 840px) {
    ul.products[data-view=list-view]>li.product .product-item__footer .add-to-cart-wrap,
    ul.products[data-view=list-view]>li.product .product-item__footer .hover-area {
        display: none;
    }
}
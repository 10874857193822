@mixin atXSmall {
  @media screen and (min-width: 480px) {
    @content;
  }
}

@mixin atSmall {
    @media screen and (min-width: 640px) {
      @content;
    }
}
  
@mixin atMedium {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin atLarge {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin atXLarge {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin BetweenSmAndXlg {
  @media screen and (max-width: 1199.98px){
    @content;
  }
}

// Container styling
.login-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  
    @include atMedium {
      flex-direction: row;
    }
  }
  
  .left-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5; // or use a specific variable if needed
    width: 100%;
  
    @include atMedium {
      width: 50%;
    }
  
    img {
      height: 12rem; // Default height for small screens
      @include atMedium {
        height: 24rem; // Height for medium screens and up
      }
    }
  }
  
  .right-section {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  
    @include atMedium {
      width: 50%;
    }
  }
  
  .content-wrapper {
    width: 100%;
    padding: 1.5rem;
  
    @include atMedium {
      width: 75%;
    }
  }
  
  // Centered text styling
  .text-center {
    text-align: center;
    margin-bottom: 2rem;
  
    h2 {
      font-size: 1.5rem;
      font-weight: bold;
      color: #333;
    }
  }
  
  .form-group {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #666;
  }
  
  .button-submit {
    background-color: #f5a741 !important;
    color: #fff !important;
    padding: 0.5rem 1rem !important;
    border: none !important;
    cursor: pointer !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    width: 100% !important;
  }
  
  // Text links
  .link-container {
    text-align: center;
    margin-top: 1rem;
    color: #666;
  
    .link {
      color: #333;
      cursor: pointer;
      text-decoration: underline;
  
      &:hover {
        color: #f5a741; // Hover color
      }
    }
  }
  